import { useEffect, useState } from 'react'

const ResizeScreenHook = () => {
  const [width, setWidth] = useState<number>(window.innerWidth)

  function handleChangeScreenWidth(e: Event) {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleChangeScreenWidth)
    return () => window.removeEventListener('resize', handleChangeScreenWidth)
  }, [])

  return { width }
}
export default ResizeScreenHook
