import { cloneDeep, unionBy } from 'lodash'
import { COption } from '../../../../components/Common/CSelect/model/COption'
import { ClientConfig } from '../../../../models/ClientConfig'
import { generateUUID } from '../../../../utils'
import {
  ClientCompanyWarehouse,
  CompanyWarehouse,
  CompanyWarehouseId,
} from '../../model/model'

let fakeData: ClientCompanyWarehouse[] = [
  {
    client: {
      code_nom: 'Client-1',
    },
    company: {
      code_nom: 'company-1',
    },
    warehouse: {
      code_nom: 'warehouse-1',
    },
  },
  {
    client: {
      code_nom: 'Client-1',
    },
    company: {
      code_nom: 'company-1',
    },
    warehouse: {
      code_nom: 'warehouse-2',
    },
  },
  {
    client: {
      code_nom: 'Client-3',
    },
    company: {
      code_nom: 'company-2',
    },
    warehouse: {
      code_nom: 'warehouse-1',
    },
  },
  {
    client: {
      code_nom: 'Client-3',
    },
    company: {
      code_nom: 'company-3',
    },
    warehouse: {
      code_nom: 'warehouse-1',
    },
  },
  {
    client: {
      code_nom: 'Client-4',
    },
    company: {
      code_nom: 'company-3',
    },
    warehouse: {
      code_nom: 'warehouse-3',
    },
  },
  {
    client: {
      code_nom: 'Client-5',
    },
    company: {
      code_nom: 'company-4',
    },
    warehouse: {
      code_nom: 'warehouse-4',
    },
  },
  {
    client: {
      code_nom: 'Client-5',
    },
    company: {
      code_nom: 'company-4',
    },
    warehouse: {
      code_nom: 'warehouse-5',
    },
  },
  {
    client: {
      code_nom: 'Client-5',
    },
    company: {
      code_nom: 'company-5',
    },
    warehouse: {
      code_nom: 'warehouse-1',
    },
  },
  {
    client: {
      code_nom: 'Client-6',
    },
    company: {
      code_nom: 'company-6',
    },
    warehouse: {
      code_nom: 'warehouse-2',
    },
  },
  {
    client: {
      code_nom: 'Client-2',
    },
    company: {
      code_nom: 'company-6',
    },
    warehouse: {
      code_nom: 'warehouse-4',
    },
  },
  {
    client: {
      code_nom: 'Client-7',
    },
    company: {
      code_nom: 'company-7',
    },
    warehouse: {
      code_nom: 'warehouse-3',
    },
  },
  {
    client: {
      code_nom: 'Client-8',
    },
    company: {
      code_nom: 'company-7',
    },
    warehouse: {
      code_nom: 'warehouse-6',
    },
  },

  {
    client: {
      code_nom: 'Client-9',
    },
    company: {
      code_nom: 'company-6',
    },
    warehouse: {
      code_nom: 'warehouse-2',
    },
  },
  {
    client: {
      code_nom: 'Client-10',
    },
    company: {
      code_nom: 'company-6',
    },
    warehouse: {
      code_nom: 'warehouse-4',
    },
  },
  {
    client: {
      code_nom: 'Client-11',
    },
    company: {
      code_nom: 'company-7',
    },
    warehouse: {
      code_nom: 'warehouse-3',
    },
  },
  {
    client: {
      code_nom: 'Client-11',
    },
    company: {
      code_nom: 'company-7',
    },
    warehouse: {
      code_nom: 'warehouse-6',
    },
  },
]
export const fakeApiGetAllSociete = (): Promise<CompanyWarehouse[]> => {
  let result: CompanyWarehouse[] = [
    {
      company: {
        code_nom: 'company-1',
      },
      warehouse: {
        code_nom: 'warehouse-1',
      },
    },
    {
      company: {
        code_nom: 'company-1',
      },
      warehouse: {
        code_nom: 'warehouse-2',
      },
    },
    {
      company: {
        code_nom: 'company-2',
      },
      warehouse: {
        code_nom: 'warehouse-1',
      },
    },
    {
      company: {
        code_nom: 'company-3',
      },
      warehouse: {
        code_nom: 'warehouse-1',
      },
    },
    {
      company: {
        code_nom: 'company-3',
      },
      warehouse: {
        code_nom: 'warehouse-3',
      },
    },
    {
      company: {
        code_nom: 'company-4',
      },
      warehouse: {
        code_nom: 'warehouse-4',
      },
    },
    {
      company: {
        code_nom: 'company-4',
      },
      warehouse: {
        code_nom: 'warehouse-5',
      },
    },
    {
      company: {
        code_nom: 'company-5',
      },
      warehouse: {
        code_nom: 'warehouse-1',
      },
    },
    {
      company: {
        code_nom: 'company-6',
      },
      warehouse: {
        code_nom: 'warehouse-2',
      },
    },
    {
      company: {
        code_nom: 'company-6',
      },
      warehouse: {
        code_nom: 'warehouse-4',
      },
    },
    {
      company: {
        code_nom: 'company-7',
      },
      warehouse: {
        code_nom: 'warehouse-3',
      },
    },
    {
      company: {
        code_nom: 'company-7',
      },
      warehouse: {
        code_nom: 'warehouse-6',
      },
    },
  ]

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(result)
    }, 1200)
  })
}

export function fakeApiGetSeByClients(
  clientIds: string[]
): Promise<ClientCompanyWarehouse[]> {
  const result = fakeData.filter((item) =>
    clientIds.includes(item.client.code_nom)
  )

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(result)
    }, 1300)
  })
}

export const fakeApiGetAllClient = (): Promise<ClientConfig[]> => {
  let fakeData: ClientConfig[] = [
    {
      code_nom: 'Client-1',
    },
    {
      code_nom: 'Client-2',
    },
    {
      code_nom: 'Client-3',
    },
    {
      code_nom: 'Client-4',
    },
    {
      code_nom: 'Client-5',
    },
    {
      code_nom: 'Client-6',
    },
    {
      code_nom: 'Client-7',
    },
    {
      code_nom: 'Client-8',
    },
    {
      code_nom: 'Client-9',
    },
    {
      code_nom: 'Client-10',
    },
    {
      code_nom: 'Client-11',
    },
  ]

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(fakeData)
    }, 1200)
  })
}

export function fakeApiGetClientsBySes(
  companyWarehouseIds: CompanyWarehouseId[]
): Promise<ClientCompanyWarehouse[]> {
  const listCompanyWarehouseId = companyWarehouseIds.map(
    (item) => `${item.company_code_nom};${item.warehouse_code_nom}`
  )
  const result = fakeData.filter((item) =>
    listCompanyWarehouseId.includes(
      `${item.company.code_nom};${item.warehouse.code_nom}`
    )
  )
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(result)
    }, 1200)
  })
}

export function seDataToOptions(seData: CompanyWarehouse[]) {
  let sePairs: COption[] = []

  for (let i = 0; i < seData.length; i++) {
    // debugger
    let pair = seData[i]
    const { company } = pair
    const option: COption = {
      uuid: generateUUID(),
      label: company.code_nom,
      value: company.code_nom,
      level: 0,
      show: true,
      childOptions: [],
    }
    for (let j = 0; j < seData.length; j++) {
      const checkedPair = seData[j]
      const { company: checkedCompany, warehouse: checkedWarehouse } =
        checkedPair
      if (checkedCompany.code_nom !== company.code_nom) continue
      let checkedOption: COption = {
        uuid: generateUUID(),
        label: checkedWarehouse.code_nom,
        value: checkedWarehouse.code_nom,
        show: false,
        level: 1,
        childOptions: [],
      }

      option.childOptions.push(checkedOption)
    }
    sePairs.push(option)
  }

  sePairs = unionBy(sePairs, 'value')
  return sePairs
}

export function clientDataToOptions(
  clientData: ClientConfig[],
  selected?: boolean
) {
  let clients = clientData.map((client) => {
    const { code_nom } = client
    const option: COption = {
      uuid: generateUUID(),
      label: code_nom,
      value: code_nom,
      show: true,
      level: 0,
      selected,
      childOptions: [],
    }

    return option
  })
  clients = unionBy(clients, 'uuid')
  return clients
}

export function trianglesToSesForceActive(triangles: ClientCompanyWarehouse[]) {
  const result = seDataToOptions(triangles)
  for (let i = 0; i < result.length; i++) {
    result[i].selected = true
    for (let j = 0; j < result[i].childOptions.length; j++) {
      result[i].childOptions[j].selected = true
    }
  }
  return result
}

export function trianglesToSesActive(triangles: ClientCompanyWarehouse[]) {
  const triangleWithCompanyActives = triangles.filter((item) => {
    const { code_nom } = item.company
    const isActive = triangles.some(
      (item) => item.company.code_nom === code_nom && item.active
    )
    return isActive
  })

  const result = seDataToOptions(triangleWithCompanyActives)
  for (let i = 0; i < result.length; i++) {
    result[i].selected = true
    for (let j = 0; j < result[i].childOptions.length; j++) {
      const currentChild = result[i].childOptions[j]
      const isActive = triangles.some(
        (item) =>
          item.company.code_nom === result[i].value &&
          item.warehouse.code_nom === currentChild.value &&
          item.active
      )

      // const show = triangles.some(item => item.warehouse.id === currentChild.value && item.active);
      result[i].childOptions[j].show = isActive
      result[i].childOptions[j].selected = isActive
      result[i].childOptions = unionBy(result[i].childOptions, 'value')
    }
  }

  return result
}

export function trianglesToClientsActive(triangles: ClientCompanyWarehouse[]) {
  const activeTriangles = cloneDeep(triangles).filter((item) => item.active)
  let result = clientDataToOptions(activeTriangles.map((item) => item.client))
  for (let i = 0; i < result.length; i++) {
    result[i].selected = true
  }
  result = unionBy(result, 'value')
  return result
}

export function trianglesToClient(triangles: ClientCompanyWarehouse[]) {
  const clients = triangles.map((item) => item.client)
  let result = clientDataToOptions(clients)
  for (let i = 0; i < result.length; i++) {
    const code_nom = result[i].value
    const triangle = triangles.find((item) => item.client.code_nom === code_nom)
    if (!triangle) continue
    result[i].selected = triangle.active
  }

  return result
}

export function trianglesToSe(triangles: ClientCompanyWarehouse[]): COption[] {
  let result: COption[] = []
  for (let i = 0; i < triangles.length; i++) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { client, company, warehouse } = triangles[i]
    const selected = triangles.some(
      (item) => item.company.code_nom === company.code_nom && item.active
    )
    const option: COption = {
      uuid: generateUUID(),
      label: company.code_nom,
      value: company.code_nom,
      show: true,
      selected,
      childOptions: [],
      level: 0,
    }
    for (let j = 0; j < triangles.length; j++) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { company: cpn, warehouse: wh } = triangles[j]
      if (cpn.code_nom !== company.code_nom) continue
      let currentOption: COption = {
        uuid: generateUUID(),
        label: warehouse.code_nom,
        value: warehouse.code_nom,
        show: true,
        selected: triangles[j].active,
        level: 1,
        childOptions: [],
      }
      option.childOptions.push(currentOption)
    }
    result.push(option)
  }

  result = unionBy(result, 'value')

  return result
}

export function trianglesToClientForceActive(
  triangles: ClientCompanyWarehouse[]
) {
  const clients = triangles.map((item) => item.client)
  let result = clientDataToOptions(clients, true)
  return result
}

export function mapStatusFromTrianglesToClients(
  triangles: ClientCompanyWarehouse[],
  clients: COption[]
) {
  for (let i = 0; i < clients.length; i++) {
    const client = clients[i]
    const code_nom = client.value
    const currentTriangles = triangles.filter(
      (item) => item.client.code_nom === code_nom
    )
    const atLeastActive = currentTriangles.some((item) => item.active)
    clients[i].selected = atLeastActive
  }
  return clients
}

export function mapStatusFromTrianglesToSes(
  triangles: ClientCompanyWarehouse[],
  sePairs: COption[]
) {
  for (let i = 0; i < sePairs.length; i++) {
    const parent = sePairs[i]
    const companyId = parent.value
    for (let j = 0; j < sePairs[i].childOptions.length; j++) {
      const child = sePairs[i].childOptions[j]
      const warehouseCodeNom = child.value
      const wasSelected = triangles.find(
        (item) =>
          item.company.code_nom === companyId &&
          item.warehouse.code_nom === warehouseCodeNom
      )?.active
      sePairs[i].childOptions[j].selected = wasSelected
      sePairs[i].childOptions[j].show = true
    }
    sePairs[i].selected = sePairs[i].childOptions.some((item) => item.selected)
    sePairs[i].show = true
  }

  return sePairs
}

export function changeStatusTrianglesFollowSe(
  triangles: ClientCompanyWarehouse[],
  pathsValue: string[],
  active: boolean
) {
  const lengthOfPath = pathsValue.length
  if (lengthOfPath === 0) return []

  if (lengthOfPath === 1) {
    const unselectedCodeNom = pathsValue[0]
    triangles = triangles.map((item) => {
      if (item.company.code_nom === unselectedCodeNom) item.active = active
      return item
    })
  }
  if (lengthOfPath === 2) {
    const [unselectedCodeNomCompany, unselectedCodeNomWarehouse] = pathsValue
    triangles = triangles.map((item) => {
      if (
        item.company.code_nom === unselectedCodeNomCompany &&
        item.warehouse.code_nom === unselectedCodeNomWarehouse
      )
        item.active = active
      return item
    })
  }

  return triangles
}
