import { ArrowLeftOutlined, SyncOutlined } from '@ant-design/icons'
import { Button, Col, notification, Row, Spin } from 'antd'
import _, { cloneDeep, uniqBy } from 'lodash'
import { useEffect, useState } from 'react'
import CLOSE_ICON from '../../../assets/images/close-success.png'
import CSelect from '../../../components/Common/CSelect/CSelect'
import { COption } from '../../../components/Common/CSelect/model/COption'
import { InputType } from '../../../enum/InputType'
import GenKeyHook from '../../../hook/genKeyHook'
import { userApi } from '../../../http/userApi'
import { generateRandomPassword_onlyDigit, removeTones } from '../../../utils'
import { getHost } from '../../../utils/getHost'
import { validateEmail } from '../../../utils/validate'
import { CInput, NameAndValue } from '../../stock/stockscreen/component/Wrapper'
import {
  ClientCompanyWarehouse,
  ClientCompanyWarehouseId,
  CompanyWarehouseId,
} from '../model/model'
import {
  changeStatusTrianglesFollowSe,
  clientDataToOptions,
  mapStatusFromTrianglesToClients,
  mapStatusFromTrianglesToSes,
  seDataToOptions,
  trianglesToClientsActive,
  trianglesToSesActive,
} from './func/functions'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import SUCCESS from '../../../assets/images/success.png'
import { UserRole, UserRoleForEmail } from '../../../enum/UserRole'
import ResizeScreenHook from '../../../hook/resizeScreenHook'
import { useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import { focusById } from '../../../hook/usePressTab'
import { forceDisableOptions } from '../../../components/Common/CSelect/func/functions'

export interface UserFormState {
  nom: string
  pre_nom: string
  mail: string
  actif: boolean
  role: UserRole
  code: string
  password: string
  username: string
  client_warehouse_company: ClientCompanyWarehouseId[]
}

export interface RequireFormState {
  nom: boolean
  pre_nom: boolean
  mail: boolean
  role: boolean
  code: boolean
  client_warehouse_company: boolean
}

export interface SendMailRequest {
  sender: string
  to: string[]
  cc: string[]
  bcc: string[]
  subject: string
  type: string
  body: {
    pre_nom: string
    nom: string
    code: string
    password: string
    url: string
  }
}

enum Select {
  Clients = 'Clients',
  Ses = 'Societe-entrepots',
  Non = 'Non',
}

export default function UtilisateurCreate() {
  const userKey = useSelector((state: RootState) => state.selector).data
    .utilisateur
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const [formState, setFormState] = useState<UserFormState>({
    nom: '',
    pre_nom: '',
    mail: '',
    actif: true,
    role: UserRole.OTHER,
    code: '',
    username: '',
    password: generateRandomPassword_onlyDigit(6),
    client_warehouse_company: [],
  })
  const [filledFormState, setFilledFormState] = useState<RequireFormState>({
    nom: false,
    pre_nom: false,
    mail: false,
    role: false,
    code: false,
    client_warehouse_company: false,
  })
  const { code } = GenKeyHook()
  const { width } = ResizeScreenHook()
  const [clients, setClients] = useState<COption[]>([])
  const [sePairs, setSePairs] = useState<COption[]>([])
  const [rotateRest, setRotateReset] = useState<number>(0)
  const [startFrom, setStartFrom] = useState<Select>(Select.Non)
  const [forceResetSelects, setForceResetSelects] = useState<number>(0)
  const [forceOpenClient, setForceOpenClient] = useState<boolean>(false)
  const [forceOpenSe, setForceOpenSe] = useState<boolean>(false)
  const [outsideClickClient, setOutsideClickClient] = useState<boolean>(false)
  const [outsideClickSe, setOutsideClickSe] = useState<boolean>(false)
  const [triangles, setTriangles] = useState<ClientCompanyWarehouse[]>([])
  const [clickedSubmit, setClickedSubmit] = useState<boolean>(false)
  const [errorMessageForceCode, setErrorMessageForceCode] = useState<string>('')
  const [seWidth, setSeWidth] = useState<number>(500)

  useEffect(() => {
    if (width < 1600) setSeWidth(400)
    else setSeWidth(500)
  }, [width])

  useEffect(() => {
    setFormState({
      ...formState,
      code: code,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code])

  useEffect(() => {
    setFilledFormState({
      ...filledFormState,
      nom: Boolean(formState.nom),
      pre_nom: Boolean(formState.pre_nom),
      mail: Boolean(formState.mail) && validateEmail(formState.mail),
      role: Boolean(formState.role),
      code: Boolean(formState.code),
      client_warehouse_company: formState.client_warehouse_company.length > 0,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState])

  useEffect(() => {
    const client_warehouse_company: ClientCompanyWarehouseId[] = triangles
      .filter((item) => item.active)
      .map((item) => {
        const result: ClientCompanyWarehouseId = {
          client_code_nom: item.client.code_nom,
          company_code_nom: item.company.code_nom,
          warehouse_code_nom: item.warehouse.code_nom,
        }
        return result
      })
    setFormState({ ...formState, client_warehouse_company })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triangles])
  const onBack = () => {
    navigate('/configurations/utilisateur?page-index=1&page-size=25')
  }

  useEffect(() => {
    if (outsideClickClient && outsideClickSe) {
      setForceOpenClient(false)
      setForceOpenSe(false)
    }
  }, [outsideClickClient, outsideClickSe])

  const onChangeInput = (nameAndValue: NameAndValue) => {
    const { name = '', value } = nameAndValue
    if (name === 'code') setErrorMessageForceCode('')
    setFormState({
      ...formState,
      [name]: value,
    })
  }

  const onResetSelect = () => {
    setForceResetSelects(forceResetSelects + 1)
    setStartFrom(Select.Non)
    setRotateReset(rotateRest + 90)

    setForceOpenClient(false)
    setForceOpenSe(false)
    setClients([])
    setSePairs([])
    setTriangles([])
  }

  const onClickSelect = (name: string) => {
    setForceOpenClient(true)
    setForceOpenSe(true)
    setOutsideClickClient(false)
    setOutsideClickSe(false)
    if (startFrom !== Select.Non) {
      return
    }
    if (name === Select.Clients) {
      setStartFrom(Select.Clients)
      // setForceOpenSe(true);
    } else {
      setStartFrom(Select.Ses)
      // setForceOpenClient(true);
    }
  }

  const onOpenClient = () => {
    if (startFrom !== Select.Non) return
    setSePairs([])
    setLoading(true)
    userApi
      .getTriangles([])
      .then((res) => {
        let result = (res.data?.entry || []).map((item) => item.client)
        result = uniqBy(result, 'code_nom')
        const clients = clientDataToOptions(result)
        // forceDisableOptions(clients, [['27041_linh']])
        setClients(clients)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const onOpenSe = () => {
    if (startFrom !== Select.Non) return
    setClients([])
    setLoading(true)
    userApi
      .getTriangles()
      .then((res) => {
        let result = res.data?.entry || []
        result = _(result)
          .uniqBy((v) => [v.company.code_nom, v.warehouse.code_nom].join())
          .value()
        let sePairs: COption[] = seDataToOptions(result)
        // forceDisableOptions(sePairs, [ ['demo_Allard', '1_Ambérieu'], ['soci0503_Soci0503', 'LI02051-CODE_LI02051'] ])
        setSePairs(sePairs)
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
      })
  }

  const onClickOutsideClient = () => {
    setOutsideClickClient(true)
  }

  const onClickOutsideSes = () => {
    setOutsideClickSe(true)
  }

  const onChangeClient = (options: COption[]) => {}

  const onSubmit = async () => {
    const filledFormState = {
      nom: Boolean(formState.nom),
      pre_nom: Boolean(formState.pre_nom),
      mail: Boolean(formState.mail) && validateEmail(formState.mail),
      role: Boolean(formState.role),
      code: Boolean(formState.code),
      client_warehouse_company: formState.client_warehouse_company.length > 0,
    }

    setFilledFormState(filledFormState)
    setClickedSubmit(true)
    const { nom, pre_nom, mail, role, code, client_warehouse_company } =
      filledFormState
    if (
      !nom ||
      !pre_nom ||
      !mail ||
      !role ||
      !code ||
      !client_warehouse_company
    )
      return

    setLoading(true)
    let formStateClone = cloneDeep(formState)
    formStateClone.username = formStateClone.code
    const sendMailData: SendMailRequest = {
      sender: '',
      to: [formStateClone.mail],
      cc: [],
      bcc: [],
      subject: `Confirmation d'inscription`,
      type: [UserRole.ADMINISTRATEUR, UserRole.SUPERADMIN].includes(
        formStateClone.role
      )
        ? UserRoleForEmail.register_bo
        : UserRoleForEmail.register_app,
      body: {
        pre_nom: formStateClone.pre_nom.trim(),
        nom: formStateClone.nom.trim(),
        code: formStateClone.code.trim(),
        password: formStateClone.password,
        url: getHost(),
      },
    }
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const result = await userApi.createUser({
        ...formStateClone,
        nom: formStateClone.nom.trim(),
        pre_nom: formStateClone.pre_nom.trim(),
        code: formStateClone.code.trim(),
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const resultEmail = await userApi.sendMailCreateUser(sendMailData)
      notification.open({
        className: 'noti noti-success',
        message: (
          <div className="flex items-center">
            <img src={SUCCESS} alt="success" width={50} className="mr-2.5" />
            Succès: Utilisateur enregistré
          </div>
        ),
        placement: 'topRight',
        closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
        duration: 3,
      })

      setLoading(false)
      onBack()
    } catch (error: AxiosError | any) {
      if (error.response.data.error.includes('Exist user on the system')) {
        setErrorMessageForceCode('Code déjà existant')
      }
      setLoading(false)
    }
  }

  const onSelectClient = (pathsValue: string[], otherPaths: string[][]) => {
    const selectedCodeNom = [pathsValue[0]]
    let trianglesClone = cloneDeep(triangles)
    const sePairsClone = cloneDeep(sePairs)
    if (startFrom === Select.Clients) {
      setLoading(true)
      userApi
        .getTrianglesByClients(selectedCodeNom)
        .then((res) => {
          const newTriangles = (res.data?.entry || []).map((item) => {
            item.active = true
            return item
          })
          trianglesClone = trianglesClone.filter(
            (item) => item.client.code_nom !== selectedCodeNom[0]
          )
          trianglesClone = trianglesClone.concat(newTriangles)
          const sePairs = trianglesToSesActive(trianglesClone)
          // forceDisableOptions(sePairs, [ ['demo_Allard', '1_Ambérieu'], ['soci0503_Soci0503', 'LI02051-CODE_LI02051'] ])
          setSePairs(sePairs)

          setTriangles(trianglesClone)
          setLoading(false)
        })
        .catch((e) => {
          setLoading(false)
        })
    } else {
      trianglesClone = trianglesClone.map((item) => {
        if (item.client.code_nom === pathsValue[0]) item.active = true
        return item
      })
      const sePairs = mapStatusFromTrianglesToSes(trianglesClone, sePairsClone)
      // forceDisableOptions(sePairs, [ ['demo_Allard', '1_Ambérieu'], ['soci0503_Soci0503', 'LI02051-CODE_LI02051'] ])
      setSePairs(sePairs)
      setTriangles(trianglesClone)
    }
  }

  const onUnselectClient = (pathsValue: string[]) => {
    const unselectedCodeNom = pathsValue[0]
    let trianglesClone = cloneDeep(triangles)
    let sePairsClone = cloneDeep(sePairs)
    if (startFrom === Select.Clients) {
      trianglesClone = trianglesClone.map((item) => {
        if (item.client.code_nom === unselectedCodeNom) item.active = false
        return item
      })
      const sePairs = trianglesToSesActive(trianglesClone)
      setSePairs(sePairs)

      const atLeastOneTriangleActive = trianglesClone.some(
        (item) => item.active
      )
      if (!atLeastOneTriangleActive) trianglesClone = []
      setTriangles(trianglesClone)
    } else {
      trianglesClone = trianglesClone.map((item) => {
        if (item.client.code_nom === unselectedCodeNom) item.active = false
        return item
      })
      const sePairs = mapStatusFromTrianglesToSes(trianglesClone, sePairsClone)
      setSePairs(sePairs)
      setTriangles(trianglesClone)
    }
  }

  const onUnselectSe = (pathsValue: string[], otherPaths: string[][]) => {
    let trianglesClone = cloneDeep(triangles)
    let clientsClone = cloneDeep(clients)
    if (startFrom === Select.Clients) {
      trianglesClone = changeStatusTrianglesFollowSe(
        trianglesClone,
        pathsValue,
        false
      )
      clientsClone = mapStatusFromTrianglesToClients(
        trianglesClone,
        clientsClone
      )
      setClients(clientsClone)
      trianglesClone = _(trianglesClone)
        .uniqBy((v) =>
          [v.client.code_nom, v.company.code_nom, v.warehouse.code_nom].join()
        )
        .value()
      setTriangles(trianglesClone)
    } else {
      if (pathsValue.length === 2) {
        const [company_code_nom, warehouse_code_nom] = pathsValue
        trianglesClone = trianglesClone.map((item) => {
          if (
            item.company.code_nom === company_code_nom &&
            item.warehouse.code_nom === warehouse_code_nom
          )
            item.active = false
          return item
        })
      } else {
        for (let i = 0; i < otherPaths.length; i++) {
          const [company_code_nom, warehouse_code_nom] = otherPaths[i]
          trianglesClone = trianglesClone.map((item) => {
            if (
              item.company.code_nom === company_code_nom &&
              item.warehouse.code_nom === warehouse_code_nom
            )
              item.active = false
            return item
          })
        }
      }

      const clients = trianglesToClientsActive(trianglesClone)
      setClients(clients)
      const atLeastOneTriangleActive = trianglesClone.some(
        (item) => item.active
      )
      if (!atLeastOneTriangleActive) trianglesClone = []
      setTriangles(trianglesClone)
    }
  }

  const onSelectSe = (pathsValue: string[], otherPaths: string[][]) => {
    let trianglesClone = cloneDeep(triangles)
    let clientsClone = cloneDeep(clients)
    trianglesClone = changeStatusTrianglesFollowSe(
      trianglesClone,
      pathsValue,
      true
    )

    if (startFrom === Select.Clients) {
      clientsClone = mapStatusFromTrianglesToClients(
        trianglesClone,
        clientsClone
      )
      setClients(clientsClone)
    } else {
      setLoading(true)

      const companyWarehouseIds: CompanyWarehouseId[] = []
      if (pathsValue.length === 2) {
        companyWarehouseIds.push({
          company_code_nom: pathsValue[0],
          warehouse_code_nom: pathsValue[1],
        })
      } else {
        for (let i = 0; i < otherPaths.length; i++) {
          const [company_code_nom, warehouse_code_nom] = otherPaths[i]
          companyWarehouseIds.push({ company_code_nom, warehouse_code_nom })
        }
      }

      userApi
        .getTrianglesBySes(companyWarehouseIds)
        .then((res) => {
          const newTriangles = (res.data?.entry || []).map((item) => {
            item.active = true
            return item
          })
          // trianglesClone = trianglesClone.filter(item => item.company.id !== companyWarehouseIds[0].company_code_nom && item.warehouse.id !== companyWarehouseIds[0].warehouse_id);

          trianglesClone = trianglesClone.concat(newTriangles)
          const clients = trianglesToClientsActive(trianglesClone)
          setClients(clients)
          trianglesClone = _(trianglesClone)
            .uniqBy((v) =>
              [
                v.client.code_nom,
                v.company.code_nom,
                v.warehouse.code_nom,
              ].join()
            )
            .value()
          setTriangles(trianglesClone)
          setLoading(false)
        })
        .catch((e) => {
          setLoading(false)
        })
    }

    trianglesClone = _(trianglesClone)
      .uniqBy((v) =>
        [v.client.code_nom, v.company.code_nom, v.warehouse.code_nom].join()
      )
      .value()
    setTriangles(trianglesClone)
  }

  const onSelectAllClient = (paths: string[][]) => {
    let pathsNorm: string[] = paths.map((item) => item[0])
    let trianglesClone = cloneDeep(triangles)
    if (startFrom === Select.Clients) {
      setLoading(true)
      userApi
        .getTrianglesByClients(pathsNorm)
        .then((res) => {
          let result = res.data?.entry || []
          result = result.map((item) => {
            if (pathsNorm.includes(item.client.code_nom)) item.active = true
            return item
          })
          // societe-entropo pairs
          const sePairs = trianglesToSesActive(result)
          setTriangles(result)
          // forceDisableOptions(sePairs, [ ['demo_Allard', '1_Ambérieu'], ['soci0503_Soci0503', 'LI02051-CODE_LI02051'] ])
          setSePairs(sePairs)
          setLoading(false)
        })
        .catch((e) => {
          setLoading(false)
        })
    } else {
      const pathsNorm = paths.map((item) => `${item[0]}`)
      trianglesClone = trianglesClone.map((item) => {
        if (pathsNorm.includes(`${item.client.code_nom}`)) item.active = true
        return item
      })
      const newSes = mapStatusFromTrianglesToSes(trianglesClone, sePairs)
      setSePairs(newSes)
      setTriangles(trianglesClone)
    }
  }

  const onSelectAllSes = (paths: string[][]) => {
    let trianglesClone = cloneDeep(triangles)
    if (startFrom === Select.Ses) {
      setLoading(true)
      const searchParam: CompanyWarehouseId[] = paths.map((item) => {
        const param: CompanyWarehouseId = {
          company_code_nom: item[0],
          warehouse_code_nom: item[1],
        }

        return param
      })
      userApi
        .getTrianglesBySes(searchParam)
        .then((res) => {
          let result = res.data?.entry || []
          const triangles = result.map((item) => {
            item.active = true
            return item
          })

          const clients = trianglesToClientsActive(result)
          setTriangles(triangles)
          setClients(clients)
          setLoading(false)
        })
        .catch((e) => {
          setLoading(false)
        })
    } else {
      const pathsNorm = paths.map((item) => `${item[0]};${item[1]}`)
      trianglesClone = trianglesClone.map((item) => {
        if (
          pathsNorm.includes(
            `${item.company.code_nom};${item.warehouse.code_nom}`
          )
        )
          item.active = true
        return item
      })
      const newClients = mapStatusFromTrianglesToClients(
        trianglesClone,
        clients
      )
      setClients(newClients)
      setTriangles(trianglesClone)
    }
  }

  const onUnselectAllClient = () => {
    setTriangles([])

    if (startFrom === Select.Clients) setSePairs([])
    else {
      let sePairsClone = cloneDeep(sePairs)
      sePairsClone = mapStatusFromTrianglesToClients([], sePairsClone)
      setSePairs(sePairsClone)
      setClients([])
    }
  }

  const onUnselectedAllSes = () => {
    setTriangles([])

    if (startFrom === Select.Ses) setClients([])
    else {
      let clientsClone = cloneDeep(clients)
      clientsClone = mapStatusFromTrianglesToClients([], clientsClone)
      setClients(clientsClone)
      setSePairs([])
    }
  }

  useEffect(() => {
    // default focus
    focusById(userKey['nom'])
  }, [])

  return (
    <Spin spinning={loading}>
      <div className="create-section-utilisateur">
        <Row className="bd-bot-page-title pb-3.5" style={{ height: '5%' }}>
          <Col className="flex items-center">
            <ArrowLeftOutlined
              className="text-primary text-2xl"
              onClick={onBack}
            />
            <span className="modal-title-heading-1 ml-3 mr-3">Création :</span>
            <span className="text-primary text-2xl">Utilisateur</span>
          </Col>
        </Row>

        <div className="main-content ">
          <div className="h-8"></div>
          <div className="bg-cl-gray pd-left-34 layout-border-radius">
            <Row
              className="pd-vertical-34"
              align={'middle'}
              gutter={{ xl: 12 }}
            >
              <Col lg={7} xl={7} xxl={5}>
                <Row align="middle">
                  <Col>
                    <span className="form-label require-filed mr-5 ">Nom</span>
                  </Col>
                  <Col>
                    <CInput
                      id={userKey['nom']}
                      nextId={userKey['prenom']}
                      name="nom"
                      placeHolder=""
                      maxLength={32}
                      className="custom-input"
                      required
                      value={formState.nom}
                      onChange={onChangeInput}
                      errorMessageForce={
                        clickedSubmit && !filledFormState.nom ? ' ' : ''
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={7} xl={7} xxl={5}>
                <Row align="middle">
                  <Col>
                    <span className="form-label require-filed mr-5">
                      Prénom
                    </span>
                  </Col>
                  <Col>
                    <CInput
                      id={userKey['prenom']}
                      previousId={userKey['nom']}
                      nextId={userKey['email']}
                      name="pre_nom"
                      placeHolder=""
                      maxLength={32}
                      className="custom-input"
                      required
                      value={formState.pre_nom}
                      onChange={onChangeInput}
                      errorMessageForce={
                        clickedSubmit && !filledFormState.pre_nom ? ' ' : ''
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={7} xl={7} xxl={5}>
                <Row align="middle">
                  <Col>
                    <span className="form-label require-filed mr-5">Mail</span>
                  </Col>
                  <Col>
                    <CInput
                      id={userKey['email']}
                      previousId={userKey['prenom']}
                      nextId={userKey['actif']}
                      name="mail"
                      placeHolder=""
                      maxLength={100}
                      className="custom-input w-full"
                      required
                      value={formState.mail}
                      validates={[
                        {
                          validateFunction: (email) => validateEmail(email),
                          message: 'Entrée Invalide',
                          order: 1,
                          realtime: true,
                        },
                      ]}
                      onChange={onChangeInput}
                      errorMessageForce={
                        clickedSubmit && !filledFormState.mail ? ' ' : ''
                      }
                    />
                  </Col>
                </Row>
              </Col>

              <Col>
                <Row align="middle">
                  <Col>
                    <span className="form-label mr-4">Actif</span>
                  </Col>
                  <Col>
                    <CInput
                      id={userKey['actif']}
                      previousId={userKey['email']}
                      nextId={userKey['role']}
                      type={[InputType.SWITCH]}
                      name="actif"
                      maxLength={64}
                      className="custom-input w-full"
                      value={formState.actif}
                      onChange={onChangeInput}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          <div className="h-4"></div>
          <div className="bg-cl-gray pd-left-34 layout-border-radius">
            <Row className="pd-vertical-34" align={'middle'}>
              <Col span={8}>
                <Row align="middle">
                  <Col>
                    <span className="form-label require-filed mr-5 ">Rôle</span>
                  </Col>
                  <Col span={16}>
                    <CInput
                      id={userKey['role']}
                      previousId={userKey['actif']}
                      nextId={userKey['code']}
                      name="role"
                      type={[InputType.SELECT]}
                      width={200}
                      className="custom-input w-full h-8"
                      options={[
                        {
                          label: 'Administrateur',
                          value: UserRole.ADMINISTRATEUR,
                        },
                        {
                          label: 'Responsable',
                          value: UserRole.RESPONSABLE,
                        },
                        {
                          label: 'Stockiste',
                          value: UserRole.STOCKISTE,
                        },
                      ]}
                      onChange={onChangeInput}
                      defaultSelectedValue="Administrateur"
                      errorMessageForce={
                        clickedSubmit && !filledFormState.role ? ' ' : ''
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <Row align="middle">
                  <Col>
                    <span className="form-label require-filed mr-5">Code</span>
                  </Col>
                  <Col span={16}>
                    <CInput
                      id={userKey['code']}
                      previousId={userKey['role']}
                      nextId={userKey['client']}
                      name="code"
                      placeHolder=""
                      maxLength={10}
                      className="custom-input"
                      value={formState?.code}
                      onChange={onChangeInput}
                      errorMessageForce={
                        (clickedSubmit && !filledFormState.code ? ' ' : '') ||
                        errorMessageForceCode
                      }
                      required
                      format={removeTones}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          <div className="h-4"></div>
          <div className="bg-cl-gray pd-left-34 relative layout-border-radius">
            <Row className="pd-vertical-34 select-area" align={'middle'}>
              <Col xl={9} xxl={8}>
                <Row align="top">
                  <Col>
                    <span className="form-label require-filed ">Clients</span>
                  </Col>
                  <Col className="absolute left-20 z-10 select-input">
                    <CSelect
                      id={userKey['client']}
                      previousId={userKey['code']}
                      nextId={userKey['societe-entrepot']}
                      name={Select.Clients}
                      onClick={onClickSelect}
                      onChange={onChangeClient}
                      optionsAreaMaxHeight={300}
                      width={300}
                      options={clients}
                      forceReset={forceResetSelects}
                      forceOpen={forceOpenClient}
                      onClickOutside={onClickOutsideClient}
                      onOpen={onOpenClient}
                      onSelect={onSelectClient}
                      onUnselect={onUnselectClient}
                      onSelectAll={onSelectAllClient}
                      onUnselectAll={onUnselectAllClient}
                      required
                      forceInvalid={
                        clickedSubmit &&
                        !filledFormState.client_warehouse_company
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col xl={10} xxl={12}>
                <Row align="top">
                  <Col>
                    <span className="form-label require-filed ">
                      Sociétés - Entrepôt(s)
                    </span>
                  </Col>
                  <Col className="absolute left-48 z-10 select-input">
                    <CSelect
                      id={userKey['societe-entrepot']}
                      previousId={userKey['client']}
                      nextId={userKey['Sauvegarder']}
                      name={Select.Ses}
                      onClick={onClickSelect}
                      optionsAreaMaxHeight={300}
                      onChange={() => {}}
                      width={seWidth}
                      options={sePairs}
                      forceReset={forceResetSelects}
                      forceOpen={forceOpenSe}
                      onClickOutside={onClickOutsideSes}
                      onOpen={onOpenSe}
                      onUnselect={onUnselectSe}
                      onSelect={onSelectSe}
                      onSelectAll={onSelectAllSes}
                      onUnselectAll={onUnselectedAllSes}
                      required
                      forceInvalid={
                        clickedSubmit &&
                        !filledFormState.client_warehouse_company
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col xl={5} xxl={4} className="flex justify-end pr-4">
                <SyncOutlined
                  rotate={rotateRest}
                  spin={loading}
                  style={{ fontSize: '30px', cursor: 'pointer' }}
                  onClick={onResetSelect}
                />
              </Col>
            </Row>
          </div>

          <div className="gap-44"></div>

          <div className="gap-44"></div>

          <div className="gap-44"></div>
          <div className="gap-44"></div>
          <div className="gap-44"></div>
          <div className="gap-44"></div>
          <div className="gap-44"></div>
          <div className="gap-44"></div>
          <div className="gap-44"></div>
        </div>

        <div className="fixed-bottom flex justify-end items-center z-9">
          <Button
            id={userKey['Sauvegarder']}
            data-next-id={userKey['nom']}
            data-previous-id={userKey['societe-entrepot']}
            disabled={loading}
            className={`btn-submit-modal text-btn-submit-last-modal `}
            onClick={onSubmit}
          >
            Sauvegarder
          </Button>
        </div>
      </div>
    </Spin>
  )
}
