import Define from '../constants/define'
import {
  ClientCompanyWarehouse,
  CompanyWarehouseId,
} from '../features/configurations/model/model'
import {
  SendMailRequest,
  UserFormState,
} from '../features/configurations/utilisateur/UtilisateurCreate'
import { ClientConfig } from '../models/ClientConfig'
import baseAxios from './clientAPI'

export interface BaseResponseForTriangle {
  code?: number
  data?: {
    entry: ClientCompanyWarehouse[]
    messageError?: string
    meta_data: {
      count: number
      limit: number
      offset: number
      total: number
    }
  }
  error?: any
  status?: string
}

export interface BaseResponseForClients {
  code?: number
  data?: {
    entry: ClientConfig[]
    messageError?: string
  }
  error?: any
  status?: string
}

const getClientsByCompanyWarehousePairs = (
  array_payload: CompanyWarehouseId[],
  limit: number = 10000,
  offset: number = 0
): Promise<BaseResponseForTriangle> => {
  const url = '/warehouse/client/all'
  const user_id = localStorage.getItem(Define.USER_ID)
  const role = localStorage.getItem(Define.ROLE)
  return baseAxios.post(url, { array_payload, limit, offset, user_id, role })
}

const getCompanyWarehousePairsByClients = (
  array_payload: string[],
  limit: number = 10000,
  offset: number = 0
): Promise<BaseResponseForTriangle> => {
  const url = '/warehouse/client/all-company-warehouse'
  const user_id = localStorage.getItem(Define.USER_ID)
  const role = localStorage.getItem(Define.ROLE)
  return baseAxios.post(url, { array_payload, user_id, role })
}

const getClients = (
  user_id: string,
  user_role: string
): Promise<BaseResponseForClients> => {
  const url = `/warehouse/accessories/filter-client?user_id=${user_id}&user_role=${user_role}`

  return baseAxios.get(url)
}

const getTrianglesByClients = (
  array_payload: string[],
  offset: number = 0,
  limit: number = 10000
): Promise<BaseResponseForTriangle> => {
  const url = '/warehouse/client/all-company-warehouse'
  const user_id = localStorage.getItem(Define.USER_ID)
  const role = localStorage.getItem(Define.ROLE)
  const body = { array_payload, offset, limit, role, user_id }
  return baseAxios.post(url, body)
}

const getTrianglesBySes = (
  array_payload: CompanyWarehouseId[],
  offset: number = 0,
  limit: number = 10000
): Promise<BaseResponseForTriangle> => {
  const url = '/warehouse/client/all'
  const user_id = localStorage.getItem(Define.USER_ID)
  const role = localStorage.getItem(Define.ROLE)
  const body = { array_payload, offset, limit, role, user_id }
  return baseAxios.post(url, body)
}

const getTriangles = (
  array_payload: string[] = [],
  offset: number = 0,
  limit: number = 10000
): Promise<BaseResponseForTriangle> => {
  const url = '/warehouse/client/all'
  const user_id = localStorage.getItem(Define.USER_ID)
  const role = localStorage.getItem(Define.ROLE)
  const body = { array_payload, offset, limit, user_id, role }
  return baseAxios.post(url, body)
}

const createUser = (data: UserFormState): Promise<UserFormState> => {
  const url = '/user'
  return baseAxios.post(url, data)
}

const sendMailCreateUser = (
  data: SendMailRequest
): Promise<SendMailRequest> => {
  const url = '/email'
  return baseAxios.post(url, data)
}

export const userApi = {
  getClientsByCompanyWarehousePairs,
  getCompanyWarehousePairsByClients,
  getClients,
  getTrianglesByClients,
  getTrianglesBySes,
  getTriangles,
  createUser,
  sendMailCreateUser,
}
